import { CanvasReference } from "./CanvasReference";

export class RunningLine extends CanvasReference {
  constructor() {
    super();
    this.runningLine = [];

    this.runningLineX = this.canvas.width;
    this.runningLineGap = 50;
  }

  create(statement) {
    const width = this.ctx.measureText(statement.description).width;
    const name = `${statement.name} - `;
    const nameWidth = this.ctx.measureText(name).width;

    this.runningLine.push({
      name: name,
      nameWidth: nameWidth,
      description: statement.description,
      descriptionWidth: width,
      x: this.runningLineX,
    });
    this.runningLineX =
      this.runningLineX + width + statement.width + this.runningLineGap;
  }

  removeInvisible() {
    this.runningLine = this.runningLine.filter((item) => {
      return item.x + item.descriptionWidth + item.nameWidth > 0;
    });
  }

  changeRunningLineX() {
    if (this.runningLine.length > 0) {
      const last = this.runningLine[this.runningLine.length - 1];
      const pos =
        last.x + last.descriptionWidth + last.nameWidth + this.runningLineGap;

      this.runningLineX = pos > this.canvas.width ? pos : this.canvas.width;
    }
  }

  draw() {
    this.removeInvisible();

    for (let i = 0; i < this.runningLine.length; i++) {
      const item = this.runningLine[i];
      this.ctx.fillText(item.name, item.x, this.canvas.height - 100);
      this.ctx.fillStyle = "#ffffff";
      this.ctx.fillText(
        item.description + ".",
        item.x + item.nameWidth,
        this.canvas.height - 100
      );
      this.ctx.fillStyle = "#0099b0";
      item.x = item.x - 2;
    }

    this.changeRunningLineX();
  }
}
