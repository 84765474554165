import { CanvasReference } from "./CanvasReference";

export default class UpdateManager extends CanvasReference {
  constructor() {
    super();
    this.updates = [];

    this.ts = Date.now();
    this.update = this.update.bind(this);
  }

  add(name, callback) {
    if (this.checkName(name) || this.checkCallback(callback)) {
      console.log("Name, or callback already exists");
      return;
    }
    this.updates.push({ name, callback });
    console.log(`add update: ${name}`);
  }

  remove(name) {
    const index = this.updates.findIndex((update) => update.name === name);
    if (index === -1) {
      console.log("No name!");
      return;
    }

    this.updates.splice(index, 1);
    console.log(`remove update: ${name}`);
  }

  checkName(name) {
    return this.updates.findIndex((update) => update.name === name) !== -1;
  }

  checkCallback(callback) {
    return (
      this.updates.findIndex((update) => update.callback === callback) !== -1
    );
  }

  update() {
    this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
    const curr = Date.now();
    const delta = (curr - this.ts) / 1000;
    this.ts = curr;
    this.updates.forEach((update) => update.callback(delta));

    window.requestAnimationFrame(this.update);
  }
}
