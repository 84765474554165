import React from "react";
import s from "./Screen.module.scss";

const Screen3 = () => {
  return (
    <div className={s.screen_wrapper}>
      <div className={s.container}>
        <div className={s.content_wrapper}>
          <span className={s.content_title}>ПРОЕКТИРУЕМ</span>
          <ul className={s.content_list}>
            <li>Марафоны</li>
            <li>Slack-курсы</li>
            <li>Telegram-курсы</li>
            <li>Трековое обучение</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Screen3;
