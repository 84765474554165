import React from "react";
import Header from "./Header";
import ChatsList from "./ChatsList";
import s from "./LeftPanel.module.css";

const LeftPanel = ({ typing, data }) => {
  return (
    <div className={s.left_panel}>
      <Header />
      <ChatsList typing={typing} data={data} />
    </div>
  );
};

export default LeftPanel;
