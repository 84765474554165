import { Controls } from "./Controls";
import shipImg from "../../media/game/ships/Nairan - Battlecruiser - Base.png";
import shipEngineImg from "../../media/game/ships/Nairan - Battlecruiser - Engine.png";
import { CanvasReference } from "./CanvasReference";

export class Ship extends CanvasReference {
  constructor() {
    super();
    this.shipImg = new Image();
    this.shipImg.src = shipImg;
    this.shipEngineImg = new Image();
    this.shipEngineImg.src = shipEngineImg;

    this.x = this.canvas.width / 2;
    this.y = this.canvas.height - 128 * 2;

    this.controls = new Controls({ x: this.x, y: this.y });

    this.color = "red";
    this.angle1 = 0;
    this.angle2 = 0;
    this.dir = 1;

    this.tickCount = 0;
    this.ticksPerFrame = 4;
    this.numberOfFrames = 8;
    this.frameIndex = 0;
  }

  draw() {
    this.controls.draw();
    this.ctx.save();

    this.ctx.translate(this.x, this.y);
    // console.log(
    //   Math.floor(this.x) === Math.floor(this.ctx.getTransform().e),
    //   Math.floor(this.y) === Math.floor(this.ctx.getTransform().f)
    // );

    // if (!this.direction(this.angle1, this.angle2)) {
    //   if (this.dir === 1) {
    //     this.angle2 += 0.05;
    //   } else if (this.dir === 0) {
    //     this.angle2 -= 0.05;
    //   }
    // } else {
    //   this.angle2 = this.angle1;
    // }
    // this.ctx.rotate(this.angle2);

    this.ctx.drawImage(
      this.shipEngineImg,
      (this.frameIndex % this.numberOfFrames) * 128,
      0,
      128,
      128,
      -64,
      -64,
      128,
      128
    );

    this.ctx.drawImage(this.shipImg, -64, -64, 128, 128);

    // this.ctx.beginPath();
    // this.ctx.strokeRect(-32, -50, 64, 84);
    // this.ctx.closePath();

    this.ctx.restore();

    if (this.tickCount > this.ticksPerFrame) {
      this.tickCount = 0;
      this.frameIndex++;
    }
    this.tickCount++;
  }

  driveToTarget() {
    // this.angle1 = Math.atan2(
    //   this.controls.mouse.y - this.y,
    //   this.controls.mouse.x - this.x
    // );
    let vecX = this.controls.mouse.x - this.x;
    let vecY = this.controls.mouse.y - this.y;
    let dist = Math.hypot(vecX, vecY);

    if (dist === 0) {
      return;
    }

    vecX /= dist;
    vecY /= dist;

    if (vecX !== 0 || vecY !== 0) {
      if (
        this.x >= this.controls.mouse.x + 5 ||
        this.x <= this.controls.mouse.x - 5 ||
        this.y >= this.controls.mouse.y + 5 ||
        this.y <= this.controls.mouse.y - 5
      ) {
        this.x += vecX * 3;
        this.y += vecY * 3;
      } else {
        this.x = this.controls.mouse.x;
        this.y = this.controls.mouse.y;
      }
    }
  }

  get coords() {
    return {
      x: this.x - 32,
      y: this.y - 50,
      w: 64,
      h: 84,
    };
  }

  direction(ang1, ang2) {
    let a1 = ang1 * (180 / Math.PI);
    if (a1 < 0) {
      a1 += 360;
    }
    let a2 = ang2 * (180 / Math.PI);
    if (a2 < 0) {
      a2 += 360;
    }
    if ((360 + a1 - a2) % 360 > 180) {
      this.dir = 0;
    } else {
      this.dir = 1;
    }
    return (
      Math.trunc(a2) <= Math.trunc(a1) + 1 &&
      Math.trunc(a2) >= Math.trunc(a1) - 1
    );
  }
}
