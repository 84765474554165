import React from "react";
import s from "./Screen.module.scss";

const Screen1 = () => {
  return (
    <div className={s.screen_wrapper}>
      <div className={s.container}>
        <div className={s.content_wrapper}>
          <span className={s.content_title}>Разрабатываем</span>
          <ul className={s.content_list}>
            <li>Симуляторы</li>
            <li>Игровые решения</li>
            <li>Мультиплеерные курсы</li>
            <li>Платформы для тренингов</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Screen1;
