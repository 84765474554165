export class CanvasReference {
  constructor() {
    this.canvas = document.getElementById("gameField");
    this.ctx = this.canvas.getContext("2d");
    this.isTabFocus = true;
    window.addEventListener("blur", () => {
      this.isTabFocus = false;
    });
    window.addEventListener("focus", () => {
      this.isTabFocus = true;
    });
  }
}
