import background from "../../media/game/field.png";
import { CanvasReference } from "./CanvasReference";

export class Background extends CanvasReference {
  constructor() {
    super();
    this.img = new Image();
    this.img.src = background;
  }

  draw() {
    this.ctx.drawImage(this.img, 0, 0, this.canvas.width, this.canvas.height);
  }
}
