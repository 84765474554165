import React from "react";
import s from "./Arrows.module.scss";
import up from "../media/arrows/k_up.svg";
import down from "../media/arrows/k_down.svg";
import left from "../media/arrows/k_left.svg";
import right from "../media/arrows/k_right.svg";
import { mover } from "../Controller";

const NavArrows = ({ activeScreen }) => {
  return (
    <React.Fragment>
      {activeScreen > 3 && (
        <div
          className={`${s.arrow_base} ${s.up_arrow}`}
          onClick={() => {
            mover.up();
          }}
          onTouchStart={() => {
            mover.up();
          }}
        >
          <img src={up} className={s.arrow_img} alt={"up_arrow"} />
        </div>
      )}
      {activeScreen < 4 && (
        <div
          className={`${s.arrow_base} ${s.down_arrow}`}
          onClick={() => {
            mover.down();
          }}
          onTouchStart={() => {
            mover.down();
          }}
        >
          <img src={down} className={s.arrow_img} alt={"down_arrow"} />
        </div>
      )}
      {(activeScreen === 1 || activeScreen === 2) && (
        <div
          className={`${s.arrow_base} ${s.right_arrow}`}
          onClick={() => {
            mover.right();
          }}
          onTouchStart={() => {
            mover.right();
          }}
        >
          <img src={right} className={s.arrow_img} alt={"right_arrow"} />
        </div>
      )}
      {(activeScreen === 2 || activeScreen === 3) && (
        <div
          className={`${s.arrow_base} ${s.left_arrow}`}
          onClick={() => {
            mover.left();
          }}
          onTouchStart={() => {
            mover.left();
          }}
        >
          <img src={left} className={s.arrow_img} alt={"left_arrow"} />
        </div>
      )}
    </React.Fragment>
  );
};

export default NavArrows;
