import React, { useEffect, useState } from "react";
import s from "./ScormScreenshots.module.css";
import Cell from "./Cell";

import img1 from "../../media/screenshots/img1.png";
import img2 from "../../media/screenshots/img2.png";
import img3 from "../../media/screenshots/img3.png";
import img4 from "../../media/screenshots/img4.png";
import img5 from "../../media/screenshots/img5.png";
import img6 from "../../media/screenshots/img6.png";
import img7 from "../../media/screenshots/img7.png";
import img8 from "../../media/screenshots/img8.png";
import img9 from "../../media/screenshots/img9.png";

const images = [
  {
    src: img1,
    id: 1,
  },
  {
    src: img2,
    id: 2,
  },
  {
    src: img3,
    id: 3,
  },
  {
    src: img4,
    id: 4,
  },
  {
    src: img5,
    id: 5,
  },
  {
    src: img6,
    id: 6,
  },
  {
    src: img7,
    id: 7,
  },
  {
    src: img8,
    id: 8,
  },
  {
    src: img9,
    id: 9,
  },
];

const ScormScreenshots = ({ activeScreen }) => {
  const [arr, setArr] = useState(
    images.sort(() => (Math.random() > 0.5 ? 1 : -1))
  );

  useEffect(() => {
    if (activeScreen === 5) {
      setArr(images.sort(() => (Math.random() > 0.5 ? 1 : -1)));
    }
  }, [activeScreen]);

  return (
    <div className={s.wrapper}>
      {arr.map((item, index) => {
        return <Cell key={index} item={item} />;
      })}
    </div>
  );
};

export default ScormScreenshots;
