import React, { useEffect, useRef } from "react";
import s from "./App.module.css";
import BezierEasing from "bezier-easing";
import { useWindowSize } from "./utils/useWindowSize";

class Mover {
  constructor(scrollRef) {
    this.animationId = undefined;
    this.div = scrollRef.current;

    this.easing = BezierEasing(0.61, 1, 0.88, 1);

    this.movingX = false;
    this.startScrollX = 0;
    this.distanceX = 0;
    this.startTimeX = null;

    this.movingY = false;
    this.startScrollY = 0;
    this.distanceY = 0;
    this.startTimeY = null;

    this.duration = 300;

    this.animateScroll = this.animateScroll.bind(this);
  }

  setNewX(x) {
    cancelAnimationFrame(this.animationId);
    this.animationId = undefined;

    this.movingX = true;
    this.distanceX = x - this.div.scrollLeft;
    this.startScrollX = this.div.scrollLeft;
    this.startTimeX = Date.now();

    this.animationId = requestAnimationFrame(this.animateScroll);
  }

  setNewY(y) {
    cancelAnimationFrame(this.animationId);
    this.animationId = undefined;

    this.movingY = true;
    this.distanceY = y - this.div.scrollTop;
    this.startScrollY = this.div.scrollTop;
    this.startTimeY = Date.now();

    this.animationId = requestAnimationFrame(this.animateScroll);
  }

  animateScroll() {
    this.animateX();
    this.animateY();
    if (this.movingX || this.movingY) {
      this.animationId = requestAnimationFrame(this.animateScroll);
    }
  }

  animateX() {
    const runtime = Date.now() - this.startTimeX;
    const relativeProgress = runtime / this.duration;
    const easedProgress = this.easing(relativeProgress);
    this.div.scrollLeft = Math.floor(
      this.startScrollX + Math.min(easedProgress, 1) * this.distanceX
    );
    if (easedProgress >= 1) {
      this.movingX = false;
    }
  }

  animateY() {
    const runtime = Date.now() - this.startTimeY;
    const relativeProgress = runtime / this.duration;
    const easedProgress = this.easing(relativeProgress);
    this.div.scrollTop = Math.floor(
      this.startScrollY + Math.min(easedProgress, 1) * this.distanceY
    );
    if (easedProgress >= 1) {
      this.movingY = false;
    }
  }

  left() {
    let round = (a, b) => Math.round(a / b) * b;
    let newX = round(
      this.div.scrollLeft - this.div.clientWidth,
      this.div.clientWidth
    );
    if (newX >= 0) {
      mover.setNewX(newX);
    } else {
      mover.setNewX(0);
    }
  }

  right() {
    let round = (a, b) => Math.round(a / b) * b;
    let newX = round(
      this.div.scrollLeft + this.div.clientWidth,
      this.div.clientWidth
    );
    if (newX <= this.div.clientWidth * 2) {
      this.setNewX(newX);
    }
  }

  up() {
    let round = (a, b) => Math.round(a / b) * b;
    let newY = round(
      this.div.scrollTop - this.div.clientHeight,
      this.div.clientHeight
    );
    if (newY >= 0) {
      mover.setNewY(newY);
    } else {
      mover.setNewY(0);
    }
  }

  down() {
    let round = (a, b) => Math.round(a / b) * b;
    let newY = round(
      this.div.scrollTop + this.div.clientHeight,
      this.div.clientHeight
    );
    if (newY <= this.div.clientHeight) {
      mover.setNewY(newY);
    }
  }
}

const minSwipeDistance = 50;
let swipeFired = false;
export let mover = null;

const offsets = {
  1: { x: 0, y: 0 },
  2: { x: 1, y: 0 },
  3: { x: 2, y: 0 },
  4: { x: 0, y: 1 },
  5: { x: 1, y: 1 },
  6: { x: 2, y: 1 },
};

const Controller = ({ children, activeScreen, scrollRef }) => {
  const touchStartX = useRef(null);
  const touchEndX = useRef(null);
  const touchStartY = useRef(null);
  const touchEndY = useRef(null);

  const scrollStartTime = useRef(null);
  const scrollFired = useRef(false);

  const [width, height] = useWindowSize();

  useEffect(() => {
    scrollRef.current.scrollLeft = width * offsets[activeScreen].x;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [width, scrollRef]);

  useEffect(() => {
    scrollRef.current.scrollTop = height * offsets[activeScreen].y;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [height, scrollRef]);

  useEffect(() => {
    mover = new Mover(scrollRef);
  }, [scrollRef]);

  return (
    <div
      ref={scrollRef}
      className={s.app_wrapper}
      id={"app_wrapper"}
      onWheel={(e) => {
        if (scrollStartTime.current === null) {
          scrollStartTime.current = Date.now();
        }
        const timeLeft = Date.now() - scrollStartTime.current;

        if (!scrollFired.current) {
          if (e.deltaY > 0) {
            mover.down();
          } else if (e.deltaY < 0) {
            mover.up();
          }
        }

        if (timeLeft < 350) {
          scrollFired.current = true;
        } else {
          scrollFired.current = false;
          scrollStartTime.current = null;
        }

        return false;
      }}
      style={{ height: height }}
      onTouchStart={(e) => {
        // if (activeScreen === 6) {
        //   return;
        // }

        swipeFired = false;
        touchEndX.current = null;
        touchEndY.current = null;
        touchStartX.current = e.targetTouches[0].clientX;
        touchStartY.current = e.targetTouches[0].clientY;
      }}
      onTouchMove={(e) => {
        // if (activeScreen === 6) {
        //   return;
        // }

        touchEndX.current = e.targetTouches[0].clientX;
        touchEndY.current = e.targetTouches[0].clientY;
        const distanceX = touchStartX.current - touchEndX.current;
        const isLeftSwipe = distanceX > minSwipeDistance && activeScreen < 4;
        const isRightSwipe = distanceX < -minSwipeDistance && activeScreen < 4;

        const distanceY = touchStartY.current - touchEndY.current;
        const isUpSwipe = distanceY > minSwipeDistance;
        const isDownSwipe = distanceY < -minSwipeDistance;

        if (isLeftSwipe) {
          if (!swipeFired) mover.right();
          swipeFired = true;
          return;
        }
        if (isRightSwipe) {
          if (!swipeFired) mover.left();
          swipeFired = true;
          return;
        }

        if (isUpSwipe) {
          if (!swipeFired) mover.down();
          swipeFired = true;
          return;
        }
        if (isDownSwipe) {
          if (!swipeFired) mover.up();
          swipeFired = true;
        }
      }}
    >
      {children}
    </div>
  );
};

export default Controller;
