import React from "react";
import s from "./ReplyBar.module.css";

const ReplyBar = () => {
  return (
    <div className={s.reply_bar}>
      <button className={s.attach}>
        <i className={`material-icons ${s.d45}`}>attach_file</i>
      </button>

      <input
        type="text"
        className={s.reply_message}
        placeholder="Write message..."
      />

      <div className={s.other_tools}>
        <button className={`${s.tool_buttons}`}>
          <i className="material-icons">face</i>
        </button>

        <button className={`${s.tool_buttons}`}>
          <i className="material-icons">mic</i>
        </button>
      </div>
    </div>
  );
};

export default ReplyBar;
