import React, { useRef, useState } from "react";

const Cell = (props) => {
  const ref = useRef(null);
  const [transform, setTransform] = useState(
    "perspective(1000px) rotateX(0deg) rotateY(0deg)"
  );

  return (
    <div
      ref={ref}
      style={{
        backgroundImage: `url(${props.item.src})`,
        backgroundSize: "cover",
        backgroundPosition: "center",

        transform: transform,
      }}
      onMouseMove={(e) => {
        const { x, y, width, height } = ref.current.getBoundingClientRect();

        const maxX = 30;
        const maxY = 30;

        const mouseX = e.clientX - x;
        const mouseY = e.clientY - y;
        const posX = mouseX / width - 0.5;
        const posY = mouseY / height - 0.5;

        setTransform(
          `perspective(1000px) rotateX(${(posY * maxY).toFixed(
            2
          )}deg) rotateY(${(posX * maxX * -1).toFixed(2)}deg)`
        );
      }}
      onMouseLeave={() => {
        setTransform("perspective(1000px) rotateX(0deg) rotateY(0deg)");
      }}
    />
  );
};

export default Cell;
