import "./App.module.css";
import ScreenHOC from "./Screens/ScreenHOC";
import { useRef, useState } from "react";
import NavArrows from "./NavArrows/NavArrows";
import SpaceGame from "./Screens/SpaceGame/SpaceGame";
import Telegram from "./Screens/Telegram/Telegram";

import Screen1 from "./Screens/Screen1";
import Screen2 from "./Screens/Screen2";
import Screen3 from "./Screens/Screen3";
import Static from "./Static/Static";
import Controller from "./Controller";
import ScormScreenshots from "./Screens/ScormScreenshots/ScormScreenshots";

function App() {
  const [activeScreen, setActiveScreen] = useState(1);
  const ref = useRef(null);

  return (
    <Controller scrollRef={ref} activeScreen={activeScreen}>
      <Static activeScreen={activeScreen} />
      <ScreenHOC number={1} setActiveScreen={setActiveScreen}>
        <Screen1 />
      </ScreenHOC>
      <ScreenHOC number={2} setActiveScreen={setActiveScreen}>
        <Screen2 />
      </ScreenHOC>
      <ScreenHOC number={3} setActiveScreen={setActiveScreen}>
        <Screen3 />
      </ScreenHOC>
      <ScreenHOC number={4} setActiveScreen={setActiveScreen}>
        <SpaceGame activeScreen={activeScreen} />
      </ScreenHOC>
      <ScreenHOC number={5} setActiveScreen={setActiveScreen}>
        <ScormScreenshots activeScreen={activeScreen} />
      </ScreenHOC>
      <ScreenHOC number={6} setActiveScreen={setActiveScreen}>
        <Telegram activeScreen={activeScreen} />
      </ScreenHOC>
      <NavArrows activeScreen={activeScreen} scrollRef={ref} />
    </Controller>
  );
}

export default App;
