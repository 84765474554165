import React from "react";
import video from "../media/video/code.mp4";
import s from "./Static.module.css";
import { mover } from "../Controller";

const Static = ({ activeScreen }) => {
  const number = activeScreen === 1 ? "01" : activeScreen === 2 ? "02" : "03";
  return (
    <React.Fragment>
      {activeScreen < 4 && (
        <div className={s.container}>
          <div className={s.header}>
            <span className={s.logo}>EdTech</span>
            <span className={s.email}>
              <a href={"mailto:team@elearnlab.ru"}>team@elearnlab.ru</a>
            </span>
          </div>
          <div className={s.counter_wrapper}>
            <span className={s.counter_current}>{number}</span>
            <span className={s.counter_all}>&nbsp;/ 3</span>
          </div>
          <div className={s.header_content}>
            <span
              onClick={() => {
                if (activeScreen === 2 || activeScreen === 3) {
                  mover.left();
                }
              }}
              className={activeScreen === 1 ? s.header_active : ""}
            >
              ЭКСКЛЮЗИВНЫЕ КУРСЫ
            </span>
            <span
              onClick={() => {
                if (activeScreen === 1) {
                  mover.right();
                }
                if (activeScreen === 3) {
                  mover.left();
                }
              }}
              className={activeScreen === 2 ? s.header_active : ""}
            >
              SCORM КУРСЫ
            </span>
            <span
              onClick={() => {
                if (activeScreen === 1 || activeScreen === 2) {
                  mover.right();
                }
              }}
              className={activeScreen === 3 ? s.header_active : ""}
            >
              ЧАТ-БОТЫ
            </span>
          </div>
        </div>
      )}
      <video
        autoPlay={true}
        loop={true}
        muted={true}
        disablePictureInPicture={true}
        className={s.video}
      >
        <source src={video} />
      </video>
      <div className={s.background} />
      <div className={s.blackCover} />
    </React.Fragment>
  );
};

export default Static;
