import { CanvasReference } from "./CanvasReference";
import { random } from "../../utils/utils";
import { RunningLine } from "./RunningLine";

const statements = [
  {
    id: 1,
    name: "Игры",
    description:
      "разработаем увлекательное эффективное обучение в формате мультиплеерных игр",
  },
  {
    id: 2,
    name: "Симуляторы",
    description:
      "смоделируем сложные системы, поможем изучить программное обеспечение, технику, освоить сложные бизнес-процессы",
  },
  // {
  //   id: 3,
  //   name: "Чат-боты",
  //   description: "телеграм и слак",
  // },
  {
    id: 4,
    name: "Telegram",
    description: "поможем обучить людей в привычной для них среде",
  },
  {
    id: 5,
    name: "Slack",
    description:
      "поможем использовать корпоративное рабочее пространство для обучения",
  },
  {
    id: 6,
    name: "Марафоны",
    description: "создадим автоматизированный непрерывный процесс обучения",
  },
  {
    id: 7,
    name: "Scorm-курсы",
    description: "соберем ваши курсы в Storyline или с помощью html5",
  },
  {
    id: 8,
    name: "Аналитика",
    description:
      "соберем данные о прохождении курсов и построим нужную вам аналитику",
  },
  {
    id: 9,
    name: "xApi",
    description: "настроим сбор и отправку данных в LRS",
  },
  {
    id: 10,
    name: "Storyline",
    description: "соберем курсы любой сложности в Storyline 360",
  },
];

const isCollectedTemplate = {
  1: false,
  2: false,
  3: false,
  4: false,
  5: false,
  6: false,
  7: false,
  8: false,
  9: false,
  10: false,
};

export class Statements extends CanvasReference {
  constructor(ship) {
    super();
    this.ship = ship;

    this.shadowBlur = 1;
    this.up = false;
    this.ctx.textBaseline = "top";

    this.ctx.shadowColor = "#0099b0";

    this.limit = 8;

    this.arr = [];
    // this.arr = [0, 0, 0, 0, 0, 0, 0, 0].map((item, index) => {
    //   return this.generateStatement(index);
    // });
    this.isCollected = {
      ...isCollectedTemplate,
    };
    this.numCollected = 0;

    this.runningLine = new RunningLine();

    this.generator();
  }

  generateStatement() {
    const item = statements[random(0, statements.length - 1)];
    const width = this.ctx.measureText(item.name).width;
    return {
      x: random(width, this.canvas.width - width),
      y: random(-10, -50),
      velX: random(-3, 3) / 3,
      velY: random(2, 4),
      width: width,
      active: false,
      ...item,
    };
  }

  generator() {
    if (this.arr.length < this.limit && this.isTabFocus) {
      this.arr.push(this.generateStatement());
    }
    setTimeout(() => {
      this.generator();
    }, 1000);
  }

  delete(index) {
    this.arr = this.arr.filter((item, i) => {
      return index !== i;
    });
  }

  removeInvisible() {
    this.arr = this.arr.filter((item) => {
      return item.y < this.canvas.height;
    });
  }

  checkIntersection(item) {
    const ship = this.ship.coords;

    // return (
    //     ship.x < item.x + item.w &&
    //     ship.y < item.y + item.h &&
    //     item.x < ship.x + ship.w &&
    //     item.y < ship.y + ship.h
    // );

    return !(
      ship.x > item.x + item.w ||
      ship.x + ship.w < item.x ||
      ship.y > item.y + item.h ||
      ship.y + ship.h < item.y
    );
  }

  draw() {
    this.removeInvisible();
    for (let i = 0; i < this.arr.length; i++) {
      const statement = this.arr[i];

      if (this.isCollected[statement.id]) {
        this.ctx.fillStyle = "rgba(0,153,176,0.5)";
      } else {
        this.ctx.fillStyle = "#0099b0";
      }
      this.ctx.fillText(statement.name, statement.x, statement.y);

      this.ctx.fillStyle = "#0099b0";

      if (
        !this.isCollected[statement.id] &&
        this.checkIntersection({
          x: statement.x,
          y: statement.y,
          w: statement.width,
          h: 16,
        })
      ) {
        this.isCollected[statement.id] = true;
        this.numCollected++;

        this.runningLine.create(statement);

        statement.x += this.canvas.width + 30;
        statement.y += this.canvas.height + 30;
      }

      statement.x += statement.velX;
      statement.y += statement.velY;
    }

    if (this.numCollected === statements.length) {
      this.numCollected = 0;
      this.isCollected = {
        ...isCollectedTemplate,
      };
    }
    this.runningLine.draw();
  }
}
