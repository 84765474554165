import React, { useEffect, useRef } from "react";
import s from "./Chat.module.css";
import { useWindowSize } from "../../../utils/useWindowSize";

const Chat = ({ data }) => {
  const ref = useRef(null);

  const [, height] = useWindowSize();

  useEffect(() => {
    if (ref.current !== null) {
      // ref.current.scrollTop += ref.current.scrollTopMax;
      ref.current.scrollTop = 10000;
    }
  }, [data]);

  return (
    <div
      ref={ref}
      id={"telegram_scroll_element"}
      className={s.chat_bg}
      style={{
        height: `${height - 140}px`,
      }}
      // onWheel={(e) => {
      //   if (ref.current !== null) {
      //     ref.current.scrollTop += Math.sign(e.deltaY) * 50;
      //   }
      // }}
    >
      {data.map((msg) => {
        return (
          <div className={`${s.message} ${s[msg.type]}`} key={msg.id}>
            {msg.text}
            {msg.type === "sent" && (
              <i className={`material-icons ${s.read_status}`}>done_all</i>
            )}

            <span className={s.timestamp}>{msg.time}</span>
          </div>
        );
      })}
    </div>
  );
};

export default Chat;
