import React, { useEffect, useState } from "react";
import doge from "../../../media/telegram/doge.png";
import robot from "../../../media/telegram/robot.png";
import as from "../Animation.module.css";
import s from "./ChatsList.module.css";

const ChatsList = ({ typing, data }) => {
  const [text, setText] = useState("");

  useEffect(() => {
    let text = "";
    data.forEach((item) => {
      if (item.type === "received") {
        text = `${item.text.slice(0, 14)}...`;
      }
    }, []);
    setText(text);
  }, [data]);

  return (
    <div className={s.chats}>
      <div className={`${s.chat_button} ${s.active}`}>
        <div className={s.chat_info}>
          <div
            className={s.image}
            style={{
              background: `url(${robot}) no-repeat center`,
              backgroundSize: "cover",
            }}
          />

          <p className={s.name}>E-learnlab Bot</p>

          {!typing && <p className={s.message}>{text}</p>}
          {typing && window.innerWidth > 720 && (
            <div
              className={as.typing_wrapper}
              style={{
                marginLeft: "80px",
                marginTop: "7px",
                color: "#ffffff",
              }}
            >
              <div className={as.dot_flashing} />
              <span className={as.typing_text}>typing</span>
            </div>
          )}
        </div>

        <div className={`${s.status} on_top`}>
          <p className={s.date}>00:04</p>
          <i className={`material-icons ${s.read}`}>done_all</i>
          <svg className={s.fixed} viewBox="0 0 24 24">
            <path d="M16,12V4H17V2H7V4H8V12L6,14V16H11.2V22H12.8V16H18V14L16,12Z" />
          </svg>
        </div>
      </div>

      <div className={s.chat_button}>
        <div className={s.chat_info}>
          <div
            className={s.image}
            style={{
              background: `url(${doge}) no-repeat center`,
              backgroundSize: "cover",
            }}
          />
          <p className={s.name}>Doge</p>
          <p className={s.message}>Wow! Very courses!</p>
        </div>

        <div className={`${s.status} normal`}>
          <p className={s.date}>Now</p>
          <p className={s.count}>42</p>
          <i className={`material-icons ${s.read}`}>done_all</i>
        </div>
      </div>
    </div>
  );
};

export default ChatsList;
