import React from "react";
import ReplyBar from "./ReplyBar";
import TopBar from "./TopBar";
import Chat from "./Chat";
import s from "./RightPanel.module.css";

const RightPanel = ({ typing, data }) => {
  return (
    <div className={s.right_panel}>
      <TopBar typing={typing} />
      <Chat data={data} />
      <ReplyBar />
    </div>
  );
};

export default RightPanel;
