import React from "react";
import s from "./Header.module.css";

const Header = () => {
  return (
    <header className={s.header}>
      <button className={s.menu}>
        <svg viewBox="0 0 24 24">
          <path d="M3,6H21V8H3V6M3,11H21V13H3V11M3,16H21V18H3V16Z" />
        </svg>
      </button>

      <input
        className={s.search_chats}
        value={""}
        readOnly
        type="search"
        placeholder="Search..."
      />
    </header>
  );
};

export default Header;
