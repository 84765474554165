import React, { useEffect } from "react";
import s from "./Screen.module.scss";
import { useInView } from "react-intersection-observer";
import { useWindowSize } from "../utils/useWindowSize";

const ScreenHOC = ({ children, color, number, setActiveScreen }) => {
  const { ref, inView, entry } = useInView({
    rootMargin: "0px",
    threshold: 0.25,
  });
  useEffect(() => {
    if (inView) {
      setActiveScreen(number);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inView]);

  const [width, height] = useWindowSize();

  return (
    <div
      ref={ref}
      style={{
        backgroundColor: color,
        height: height,
        width: width,
      }}
      className={s.screen_hoc}
    >
      {children}
    </div>
  );
};

export default ScreenHOC;
