import redNova from "../../media/game/smallstars/Starry background - Layer X - Big Star.png";
import greenNova from "../../media/game/smallstars/Starry background - Layer X - Big Star 2.png";
import pulsarNova from "../../media/game/smallstars/Starry background - Layer X -Rotary Star.png";
import earth from "../../media/game/smallstars/Earth-Like planet - Without back glow.png";
import { CanvasReference } from "./CanvasReference";
import { random } from "../../utils/utils";

export class CosmosStars extends CanvasReference {
  constructor() {
    super();
    this.maxX = this.canvas.width;
    this.maxY = this.canvas.height;

    this.stars = [];
    this.starsLimit = 100;

    for (let i = 0; i < this.starsLimit; i++) {
      this.stars.push({
        x: random(0, this.maxX),
        y: random(0, this.maxY),
        speed: random(4, 8),
        height: random(1, 6),
      });
    }

    this.novas = [];
    this.novasLimit = 15;
    this.novasImgs = [null, null, null];
    this.novasImgs[0] = new Image();
    this.novasImgs[0].src = redNova;
    this.novasImgs[1] = new Image();
    this.novasImgs[1].src = greenNova;
    this.novasImgs[2] = new Image();
    this.novasImgs[2].src = pulsarNova;

    for (let i = 0; i < this.novasLimit; i++) {
      this.novas.push({
        x: random(0, this.maxX),
        y: random(0, this.maxY),
        src: this.novasImgs[random(0, 2)],
      });
    }

    this.earth = new Image();
    this.earth.src = earth;
    this.earthX = 150;
    this.earthY = 150;
    this.earthVelX = 5;
    this.earthVelY = 5;

    this.tickCount = 0;
    this.ticksPerFrame = 8;
    this.numberOfFrames = 77;
    this.frameIndex = 0;
  }

  draw() {
    this.ctx.save();
    this.ctx.beginPath();
    this.ctx.strokeStyle = "rgb(255,255,255)";
    for (let i = 0; i < this.starsLimit; i++) {
      const item = this.stars[i];
      this.ctx.moveTo(item.x, item.y);
      this.ctx.lineTo(item.x, item.y + item.height);
      item.y += item.speed;
      if (item.y > this.maxY) {
        item.y = 0;
        item.x = random(0, this.maxX);
        item.speed = random(4, 8);
        item.height = random(1, 6);
      }
    }
    this.ctx.stroke();
    this.ctx.closePath();
    this.ctx.restore();
    this.drawNovas();
    // this.drawEarth();
    if (this.tickCount > this.ticksPerFrame) {
      this.tickCount = 0;
      this.frameIndex++;
    }
    this.tickCount++;
  }

  drawEarth() {
    this.ctx.drawImage(
      this.earth,
      (this.frameIndex % this.numberOfFrames) * 96,
      0,
      96,
      96,
      this.earthX,
      this.earthY,
      96,
      96
    );
    this.earthX += this.earthVelX;
    this.earthY += this.earthVelY;
    if (this.earthX > this.maxX + 96 || this.earthY > this.maxY + 96) {
      this.earthX = random(0, this.maxX - 96);
      this.earthY = -random(96, 96 * 5);
      this.earthVelX = random(1, 4);
      this.earthVelY = random(4, 8);
      this.earthVelX *= this.earthVelX % 2 === 0 ? -1 : 1;
    }
  }

  drawNovas() {
    for (let i = 0; i < this.novasLimit; i++) {
      const item = this.novas[i];
      this.ctx.drawImage(
        item.src,
        (this.frameIndex % 9) * 640,
        0,
        640,
        360,
        item.x,
        item.y,
        160,
        90
      );
    }
  }
}
