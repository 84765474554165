import { CanvasReference } from "./CanvasReference";

export class Controls extends CanvasReference {
  constructor({ x, y }) {
    super();
    this.mouse = {
      x: x,
      y: y,
    };

    this.canvas.addEventListener("click", (e) => {
      this.mouse.x = e.x;
      this.mouse.y = e.y;
    });
  }
  draw() {}
}
