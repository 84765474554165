import React from "react";
import as from "../Animation.module.css";
import s from "./TopBar.module.css";
import robot from "../../../media/telegram/robot.png";

const TopBar = ({ typing }) => {
  return (
    <div className={s.top_bar}>
      <div className={s.left_side}>
        <div
          className={s.image}
          style={{
            background: `url(${robot}) no-repeat center`,
            backgroundSize: "cover",
          }}
        />
        <p className={s.chat_name}>E-learnlab Bot</p>
        {typing && (
          <div className={as.typing_wrapper}>
            <div className={as.dot_flashing} />
            <span className={as.typing_text}>typing</span>
          </div>
        )}
        {!typing && <p className={s.chat_status}>Online</p>}
      </div>

      <div className={s.right_side}>
        <button className={`${s.tb_button}`}>
          <i className="material-icons">&#xE8B6;</i>
        </button>
        <button className={`${s.tb_button}`}>
          <i className="material-icons">more_vert</i>
        </button>
      </div>
    </div>
  );
};

export default TopBar;
