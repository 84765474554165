import React, { useEffect, useRef } from "react";
import s from "./SpaceGame.module.css";
import { Ship } from "./Ship";
import { CosmosStars } from "./CosmosStars";
import { Statements } from "./Statements";
import { Background } from "./Background";
import { CanvasReference } from "./CanvasReference";
import UpdateManager from "./UpdateManager";
import { useWindowSize } from "../../utils/useWindowSize";

class MainScene extends CanvasReference {
  constructor() {
    super();
    this.pause = false;
    this.updates = new UpdateManager();
    this.ship = new Ship();
    this.cosmosStars = new CosmosStars();
    this.statements = new Statements(this.ship, this.isPaused);
    this.background = new Background();

    this.start();
  }

  start() {
    this.ctx.fillStyle = "#0099b0";
    this.ctx.strokeStyle = "#0099b0";
    this.ctx.font = "16px PressStart2P";
    this.updates.add("MainScene", (this.update = this.update.bind(this)));

    this.updates.update();
  }

  update(delta) {
    this.background.draw();

    this.cosmosStars.draw();

    this.statements.draw();
    if (this.pause) {
      return;
    }
    this.ship.draw();
    this.ship.driveToTarget();
  }

  handleScreenSizeChange({ width, height }) {
    this.canvas.width = width;
    this.canvas.height = height;
    this.ctx.font = "16px PressStart2P";
    this.ship = new Ship();
    this.statements = new Statements(this.ship);
    this.cosmosStars = new CosmosStars();
  }

  isPaused() {
    return this.pause;
  }
}
const SpaceGame = ({ activeScreen }) => {
  const [width, height] = useWindowSize();
  const gameRef = useRef(null);

  useEffect(() => {
    if (gameRef.current === null) {
      gameRef.current = new MainScene();
    } else {
      gameRef.current.handleScreenSizeChange({ width, height });
    }
  }, [width, height]);

  useEffect(() => {
    gameRef.current.pause = activeScreen !== 4;
  }, [activeScreen]);

  return (
    <div>
      <canvas
        id="gameField"
        width={width}
        height={height}
        className={s.game_field}
      />
      <div
        style={{
          fontFamily: "PressStart2P",
          position: "fixed",
          fontSize: "16px",
          top: 50,
          left: 50,
          color: "wheat",
        }}
      />
    </div>
  );
};

export default SpaceGame;
