import React from "react";
import s from "./Telegram.module.css";
import LeftPanel from "./LeftPanel/LeftPanel";
import RightPanel from "./RightPanel/RightPanel";
import { useEffect, useRef, useState } from "react";

const messages = [
  {
    id: "t1",
    type: "typing",
    delay: 2000,
  },
  {
    text: "Привет-привет!👋 Давай знакомиться и дружить! Чем я могу тебе помочь?",
    type: "received",
    id: 1,
    delay: 2000,
    time: "11:15",
  },
  {
    text: "Я хочу обучать новых сотрудников компании. Я слышал, что теперь это можно делать в Telegram.",
    type: "sent",
    id: 2,
    delay: 1000,
    time: "11:16",
  },
  {
    id: "t2",
    type: "typing",
    delay: 2000,
  },
  {
    text: "Да, так и есть. Курс может быть в виде марафона или сценария.",
    type: "received",
    id: 3,
    delay: 1000,
    time: "11:17",
  },
  {
    id: "t3",
    type: "typing",
    delay: 2000,
  },
  {
    text: "Марафоны - это пассивные курсы. Я буду присылать обучаемому информацию в конкретные дни и время, соберу обратную связь и передам наставнику.",
    type: "received",
    id: 4,
    delay: 1000,
    time: "11:17",
  },
  {
    id: "t4",
    type: "typing",
    delay: 2000,
  },
  {
    text: "Сценарии - это активные курсы. Обучаемый взаимодействует со мной с помощью кнопок, которые ведут его по разветвленному сценарию.",
    type: "received",
    id: 5,
    delay: 2000,
    time: "11:17",
  },
  {
    text: "О, очень интересно. Давайте обсудим подробнее.",
    type: "sent",
    id: 6,
    delay: 800,
    time: "11:18",
  },
  {
    id: "t4",
    type: "typing",
    delay: 2000,
  },
  {
    text: "Напишите нам: team@elearnlab.ru",
    type: "received",
    id: 7,
    delay: 3000,
    time: "11:18",
  },
];

const Telegram = ({ activeScreen }) => {
  const [data, setData] = useState([]);
  const [typing, setTyping] = useState(true);
  const currentMessage = useRef(0);
  const timeoutRef = useRef(null);

  useEffect(() => {
    if (activeScreen === 6) {
      const pushMessage = (data) => {
        const newMsg = messages[currentMessage.current];

        let newData = data;
        if (newMsg.type === "typing") {
          setTyping(true);
        } else {
          setTyping(false);
          newData = [...data, newMsg];
          setData(newData);
        }
        currentMessage.current++;
        if (currentMessage.current < messages.length) {
          timeoutRef.current = setTimeout(
            () => pushMessage(newData),
            newMsg.delay
          );
        }
        // else {
        //   currentMessage.current = 0;
        //   setData([]);
        //   setTimeout(() => pushMessage([]), 2000);
        // }
      };
      pushMessage(data);
    } else {
      currentMessage.current = 0;
      setData([]);
      setTyping(true);
      clearTimeout(timeoutRef.current);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeScreen]);

  return (
    <div className={s.wrapper}>
      <LeftPanel data={data} typing={typing} />
      <RightPanel data={data} typing={typing} />
    </div>
  );
};

export default Telegram;
