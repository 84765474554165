import React from "react";
import s from "./Screen.module.scss";

const Screen2 = () => {
  return (
    <div className={s.screen_wrapper}>
      <div className={s.container}>
        <div className={s.content_wrapper}>
          <span className={s.content_title}>СОЗДАЕМ</span>
          <ul className={s.content_list}>
            <li>Курсы в Storyline</li>
            <li>HTML5 курсы</li>
            <li>xApi Оценку</li>
            <li>Системы аналитики</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Screen2;
